<template>
    <component :is="componentTag" v-if="component.isVisible()" class="cms-component" :class="[`cms-component-${component.type}`, {'selected': selected}]"
               :link="link" :bound-item="componentTag === 'cms-link' ? boundItem : undefined"
               :contenteditable="contenteditable" @input="$emit('input', $event)" @blur="$emit('blur', $event)">
        <slot></slot>
    </component>
</template>

<script>

// cms-component #hot-reload-debug
import CmsLink from "@/components/cms/cms-link.vue";

export default {
    name: `cms-component`,
    components: {CmsLink},
    props: {
        component: {type: Object, required: true},
        boundItem: {type: Object},
        block: {type: Object},
        selected: {type: Boolean},
        align: {type: String},
        link: {type: [String, Object]},
        isChild: {type: Boolean},
        contenteditable: {type: Boolean}
    },
    computed: {
        componentTag() {
            return this.link ? `cms-link` : `div`;
        },
        useBinding() {
            return this.component.useBinding && this.boundItem;
        }
    }
}
</script>

<style lang="scss" scoped>
.cms-component {
    & + .cms-component {
        margin-top: var(--block-h-spacing);
    }
}

.cms-container.horizontal {
    .cms-component {
        & + .cms-component {
            margin-top: 0;
        }
    }
}
</style>
