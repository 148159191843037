<template>
    <cms-block v-bind="$props" block-component="b-navbar" @click.native="onSelected" :style="blockStyle"
               :block-component-params="{ 'sticky': !isEditMode }" :block-class="[`anchor-${block.mediaProxy.anchor} orientation-${block.orientation}`]"
               :container-component-params="{ fillParent: block.orientation === 'vertical' }" :horizontal="block.orientation === 'horizontal'">
        <b-navbar-nav>
            <cms-component-image :component="block.logo" v-bind="componentProps"/>
            <cms-component-navigation-links class="menu-before" :component="block.leftLinks" v-bind="componentProps" in-main-menu @collapse-nav-menu="toggleMenu()"/>
            <cms-component-navigation-links class="menu-center" :component="block.links" v-bind="componentProps" in-main-menu @collapse-nav-menu="toggleMenu()"/>
            <cms-component-navigation-links class="menu-after" :component="block.rightLinks" v-bind="componentProps" in-main-menu @collapse-nav-menu="toggleMenu()"/>
        </b-navbar-nav>
        <template v-if="isCollapsing">
            <b-collapse v-model="mobileMenuVisible" class="mobile-menu">
                <cms-component-navigation-links :component="block.leftLinks" @navigated="toggleMenu()" v-bind="componentProps" in-main-menu collapsed/>
                <cms-component-navigation-links :component="block.links" @navigated="toggleMenu()" v-bind="componentProps" in-main-menu collapsed/>
                <cms-component-navigation-links :component="block.rightLinks" @navigated="toggleMenu()" v-bind="componentProps" in-main-menu collapsed/>
            </b-collapse>
        </template>
    </cms-block>
</template>

<script>
import CmsLink from "@/components/cms/cms-link.vue";
import CmsBlock from "@/components/editor/rendering/blocks/cms-block.vue";
import CmsComponentButton from "@/components/editor/rendering/components/cms-component-button.vue";
import CmsComponentImage from "@/components/editor/rendering/components/cms-component-image.vue";
import CmsComponentNavigationLinks from "@/components/editor/rendering/components/cms-component-navigation-links.vue";
import CmsComponentSocialLinks from "@/components/editor/rendering/components/cms-component-social-links.vue";
import CmsComponentText from "@/components/editor/rendering/components/cms-component-text.vue";
import {changeLanguage} from "../../../../../vue-components/helpers/i18n.js";

// cms-block-menu #hot-reload-debug
export default {
    name: `cms-block-menu`,
    components: {CmsComponentSocialLinks, CmsComponentNavigationLinks, CmsLink, CmsComponentImage, CmsComponentButton, CmsComponentText, CmsBlock},
    extends: CmsBlock,
    computed: {
        blockStyle() {
            return {
                "--menu-size": `${this.block.mediaProxy.size}px`
            }
        },
        isCollapsing() {
            return this.block.links.isCollapsing() || this.block.leftLinks.isCollapsing() || this.block.rightLinks.isCollapsing();
        }
    },
    data() {
        return {
            mobileMenuVisible: false
        }
    },
    methods: {
        toggleMenu() {
            this.mobileMenuVisible = !this.mobileMenuVisible;
        },
        changeLanguage() {
            if (!this.isEditMode) {
                changeLanguage();
                this.$router.go();
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.cms-block-menu {
    padding: 0;
    z-index: 1000;

    .cms-container {
        display: flex;
        align-items: center;
        padding: 0;
        background-color: inherit;

        .navbar-collapse {
            width: 100%;
        }

        .navbar-nav {
            align-items: center;
        }
    }

    &.orientation-horizontal {
        position: sticky;
        width: 100%;
        height: var(--menu-height);

        ::v-deep > .cms-container {
            height: var(--menu-height);
        }

        .navbar-nav {
            height: var(--menu-height);
            width: 100%;
        }

        ::v-deep .menu-before {
            margin-right: auto;
            justify-content: start;
        }
        ::v-deep .menu-after {
            margin-left: auto;
            justify-content: end;
        }
    }

    &.orientation-vertical {
        position: fixed;
        z-index: 1;
        //height: 100%;
        width: var(--menu-width);

        ::v-deep > .cms-container {
            width: var(--menu-width);
            max-height: var(--floating-menu-height);
        }

        .navbar-nav {
            flex-direction: column !important;
            flex-grow: 1;
            width: var(--menu-width);
        }

        .menu-before {
            margin-bottom: auto;
            justify-content: start;
        }
        .menu-after {
            margin-top: auto;
            justify-content: end;
        }
    }

    &.anchor-left {
        top: 0;
        left: 0;
        bottom: 0;
    }

    &.anchor-bottom {
        position: fixed;
        top: auto;
        bottom: 0;
        left: 0;
        right: 0;
    }

    &.anchor-right {
        top: 0;
        right: 0;
        bottom: 0;
    }

    > hr {
        width: 100%;
        margin: 0;
        border-bottom: 1px solid rgba(white, 0.05);
        //border-top: 1px solid rgba(black, 0.5);
    }

    .navbar-brand {
        display: flex;
        height: var(--menu-size);
        align-items: center;
        justify-content: center;
    }
}

.mobile-toggle {
    flex: 1;
}
.mobile-logo {
    margin-right: 0;
    max-width: initial !important;
    flex: 2;
}

.mobile-menu {
    position: fixed !important;
    top: var(--page-padding-top) !important;
    left: var(--page-padding-left) !important;
    right: var(--page-padding-right) !important;
    bottom: var(--page-padding-bottom) !important;
    z-index: 1001;
    background: var(--scheme-bg);
}

.web-editor {
    .mobile-menu {
        // leave space for padding rule panel
        top: calc(var(--page-padding-top) + 25px) !important;
    }
}

.floating-menu {
    .cms-block-menu {
        &.orientation-vertical {
            background: transparent !important;

            ::v-deep > .cms-container {
                background: var(--scheme-bg);
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
            }
        }
    }
}
</style>
