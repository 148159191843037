<template>
    <div class="cms-block-builder">
        <cms-block-menu v-if="!noNavigation && !noHeader && cmsWebsite.menu.block" v-on="$listeners"
                    :block="cmsWebsite.menu.block" :current-language="currentLanguage" :editable="editable" :block-selection="blockSelection"/>
        <cms-block-selector v-for="(block, index) in blocks" :key="block.id" :index="index" v-on="$listeners"
                   :block="block" :current-language="currentLanguage" :editable="editable" :block-selection="blockSelection"/>
        <slot></slot>
        <cms-block-footer v-if="!noNavigation && !noFooter && cmsWebsite.footer.block" v-on="$listeners"
                  :block="cmsWebsite.footer.block" :current-language="currentLanguage" :editable="editable" :block-selection="blockSelection"/>
    </div>
</template>

<script>
// cms-block-builder #hot-reload-debug
import CmsBlockFooter from "@/components/editor/rendering/blocks/cms-block-footer.vue";
import CmsBlockMenu from "@/components/editor/rendering/blocks/cms-block-menu.vue";

export default {
    name: `cms-block-builder`,
    components: {
        CmsBlockMenu,
        CmsBlockFooter,
        CmsBlockSelector: () => import(`@/components/editor/rendering/blocks/cms-block-selector.vue`)
    },
    props: {
        value: {type: Object}, // blockSelected
        blockSelection: {type: Array},
        noNavigation: {type: Boolean},
        noHeader: {type: Boolean},
        noFooter: {type: Boolean},
        currentLanguage: {type: String, default: `fr`},
        blocks: {type: Array, required: true},
        editable: {type: Boolean}
    },
    computed: {
        blockSelected: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit(`input`, value);
            }
        }
    }
};
</script>

<style scoped lang="scss">
.cms-block-builder {
    padding-bottom: var(--page-padding-bottom);
}

.mobile {
    touch-action: manipulation;
}
</style>
