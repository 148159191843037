<template>
    <component :is="componentTag" v-if="isVisible" class="cms-link" v-on="$listeners" :to="to" :href="href" :target="target" @click="onClick">
        <slot name="before"/>
        <slot></slot>
        <slot name="after"/>
    </component>
</template>

<script>
// cms-link #hot-reload-debug
import CmsLink from "@/helpers/cms/CmsLink.js";
import {changeLanguage} from "../../../vue-components/helpers/i18n.js";

export default {
    name: `cms-link`,
    props: {
        link: {type: [Object, String]},
        inMainMenu: {type: Boolean},
        navItem: {type: Boolean}
    },
    computed: {
        componentTag() {
            if (this.navItem) {
                return `b-nav-item`;
            } else if (this.to) {
                return `router-link`;
            } else if (this.href) {
                return `a`;
            } else {
                return `div`;
            }
        },
        // ensure that page link are always up-to-date
        cmsLink() {
            if (this.link) {
                return new CmsLink(this.link);
            } else {
                return this.link;
            }
        },
        to() {
            if (this.isEditMode || !this.cmsLink) {
                return null;
            }

            return this.cmsLink.to;
        },
        href() {
            if (this.isEditMode || !this.cmsLink) {
                return null;
            }

            if (this.to) {
                return `${window.location.origin}${this.to}`;
            }

            return this.cmsLink.href;
        },
        target() {
            if (this.to || !this.cmsLink) {
                return null;
            }
            return this.cmsLink.openInNewTab ? `_blank` : ``;
        },
        isVisible() {
            if (!this.cmsLink) {
                console.error(`Link missing for CMS link`, this);
                return true;
            }

            if (this.cmsLink.type === `collapse` && !this.inMainMenu) {
                return false;
            } else if (this.cmsLink.type === `wheelchair` && !this.isKioskDevice) {
                return false;
            } else if (this.cmsLink.type === `collapse` && !this.isMobileLayoutOrPreview) {
                return false;
            }

            if (!this.cmsLink.mediaQuery) {
                return true;
            } else {
                return this.cmsLink.mediaProxy.visible;
            }
        }
    },
    methods: {
        onClick() {
            if (this.isEditMode || !this.cmsLink)
                return;

            switch (this.cmsLink.type) {
                case `back`:
                    window.history.go(-1);
                    break;
                case `next`:
                    window.history.go(1);
                    break;
                case `collapse`:
                    this.$emit(`collapse-nav-menu`);
                    break;
                case `wheelchair`:
                    this.$store.commit(`setAccessibilityWheelchair`, !this.$store.state.accessibility.wheelchair);
                    break;
                case `language`:
                    changeLanguage();
                    window.location.reload();
                    break;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
