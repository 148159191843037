<template>
    <div class="preview-responsive-container website-page cms-website" :style="[website.styleVariables, _pageStyle]"
         :class="[`preview-layout-${$store.state.previewMode}`, `menu-anchor-${cmsWebsite.menu.block.anchor}`]">
        <slot name="above"/>
        <div class="scrollable-content overflow">
            <slot></slot>
        </div>
        <slot name="below"/>
    </div>
</template>

<script>
export default {
    name: `preview-responsive-container`,
    computed: {
        _pageStyle() {
            if (!this.cmsPage) {
                return ``;
            }

            let style = {};
            if (this.cmsPage.background === `color` && this.cmsPage.backgroundColor) {
                style[`--page-bg`] = this.cmsWebsite.getColor(this.cmsPage.backgroundColor);
                style.background = this.cmsWebsite.getColor(this.cmsPage.backgroundColor);
            } else if (this.cmsPage.background === `image` && this.cmsPage.backgroundImage) {
                style.background = `url("${this.cmsPage.backgroundImage}") no-repeat center center`;
                style.backgroundSize = `cover`;
            }

            return style;
        }
    }
}
</script>
<style lang="scss" scoped>
@import "../assets/style/website/website.scss";

.preview-responsive-container {
    min-height: var(--app-height);
    padding-left: var(--page-padding-left);
    padding-right: var(--page-padding-right);

    &.preview-layout-mobile,
    &.preview-layout-tablet,
    &.preview-layout-kiosk {
        border-left: 2px dashed #333;
        border-right: 2px dashed #333;
    }

    &.preview-layout-mobile {
        margin: 0 auto;
        width: 400px;
    }

    &.preview-layout-tablet {
        margin: 0 auto;
        width: 800px;
    }

    &.preview-layout-kiosk {
        margin: 0 auto;
        width: 1080px;
    }

    .scrollable-content {
        overflow: hidden auto;
        height: var(--app-height);
    }
}
</style>


<style lang="scss">
.preview-responsive-container {
    contain: layout;
}
</style>
