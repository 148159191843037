<template>
    <div class="cms-container" :class="['container-' + mode, 'align-' + align, horizontal ? 'horizontal' : 'vertical',
         { 'limit-visibles': showLimit, 'fill-parent': fillParent }]">
        <slot></slot>
    </div>
</template>

<script>

// cms-container  #hot-reload-debug
export default {
    name: `cms-container`,
    props: {
        mode: {type: String, default: `contained`},
        align: {type: String, default: `inherit`},
        fillParent: {type: Boolean},
        horizontal: {type: Boolean},
        showLimit: {type: Boolean}
    }
}
</script>

<style lang="scss" scoped>
.cms-container {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    max-width: 100%;
    background: var(--scheme-bg);
    border-radius: var(--container-radius);

    &.horizontal {
        flex-direction: row;
    }

    &.container-extended {
        width: var(--page-width);
        margin-left: auto;
        margin-right: auto;

        .cms-container {
            width: var(--page-width);
        }

        &.limit-visibles {
            border-left: 1px dashed #777;
            border-right: 1px dashed #777;
        }
    }

    &.container-contained {
        width: var(--page-width);
        margin-left: auto;
        margin-right: auto;

        .cms-container {
            width: 100%;
        }

        &.limit-visibles {
            border-left: 1px dashed #777;
            border-right: 1px dashed #777;
        }
    }

    &.fill-parent {
        width: 100%;
        height: 100%;
    }

    ::v-deep .flex {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .cms-container.align-left,
    &.align-left {
        --container-h-align: start;

        &, ::v-deep .flex {
            text-align: start;
            align-items: start;
        }
    }

    .cms-container.align-center,
    &.align-center {
        --container-h-align: center;

        &, ::v-deep .flex {
            text-align: center;
            align-items: center;
        }
    }

    .cms-container.align-right,
    &.align-right {
        --container-h-align: end;

        &, ::v-deep .flex {
            text-align: end;
            align-items: end;
        }
    }
}
</style>
