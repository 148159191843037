<template>
    <cms-component v-bind="$props" :style="containerStyle">
        <cms-link :link="currentLink" :bound-item="boundItem" class="btn btn-scheme cms-button" :style="buttonStyle"
           :class="[`btn-${component.mediaProxy.size}`, `btn-type-${component.mediaProxy.buttonType}`]">
            <template #before v-if="component.mediaProxy.leftIcon">
                <i class="fa-fw mr-1" :class="[fontAwesomePrefix, component.mediaProxy.leftIcon]"/>
            </template>
            <span :contenteditable="selected" @input="edited" @blur="onBlur" v-text="text"></span>
            <template #after v-if="component.mediaProxy.rightIcon">
                <i class="fa-fw ml-1" :class="[fontAwesomePrefix, component.mediaProxy.rightIcon]"/>
            </template>
        </cms-link>
    </cms-component>
</template>

<script>
import CmsComponent from "@/components/editor/rendering/components/cms-component.vue";

// cms-component-button #hot-reload-debug
export default {
    name: `cms-component-button`,
    extends: CmsComponent,
    components: {CmsComponent},
    data() {
        return {
            text: ``
        }
    },
    computed: {
        currentLink() {
            let link;
            if (this.useBinding && this.component.linkField) {
                link = this.boundItem.getField(this.component.linkField);
            }
            if (!link) {
                link = this.component.link;
            }
            return link;
        },
        containerStyle() {
            return {
                fontWeight: this.component.bold ? `bold` : ``,
                fontStyle: this.component.italic ? `italic` : `normal`
            }
        },
        buttonStyle() {
            let style = {}
            if (this.component.mediaProxy.borderRadius && this.component.mediaProxy.borderRadius !== `var(--border-radius)`) {
                style[`--border-radius`] = this.component.mediaProxy.borderRadius;
            }
            return style;
        }
    },
    mounted() {
        this.init();
    },
    created() {
        this.init();
    },
    methods: {
        init() {
            if (this.isEditMode) {
                this.text = this.component.getText(this.displayLanguage);
            } else {
                this.text = this.tr(this.component.text);
            }
        },
        input() {

        },
        edited(value) {
            // console.log(`edited`, value.target.innerHTML);
            if (typeof this.component.newText !== `object`) {
                this.component.newText = {};
            }
            this.component.newText[this.displayLanguage] = value.target.innerHTML;
        },
        onBlur() {
            // console.log(`blurred`, this.component.newText[this.displayLanguage]);
            this.component.applyChanges();
            this.init();
        }
    }
}
</script>

<style lang="scss" scoped>
.cms-button {
    display: flex;
    align-items: center;
    justify-content: center;
}

</style>
