<template>
    <cms-component v-bind="$props" :style="containerStyle"
                   :class="[`layout-${component.mediaProxy.layout}`, `size-${component.mediaProxy.linkSizeMode}`, {'fill-size': component.mediaProxy.fillSize, 'show-separator': component.mediaProxy.separator}]">
        <b-btn v-for="(link, index) in component.links" :key="`link-${index}`" variant="scheme"
                   :href="linkHref(link)" target="_blank" :class="[`btn-${component.mediaProxy.size}`, `btn-type-${component.mediaProxy.buttonType}`]">
            <i v-if="component.mediaProxy.display !== 'text'" class="icon fa-fw" :class="[link.glyph, `fa-${component.mediaProxy.iconSize}`, fontAwesomePrefix]"/>
            <span class="name" v-if="component.mediaProxy.display !== 'icon'">{{tr(link.name, displayLanguage)}}</span>
        </b-btn>
    </cms-component>
</template>

<script>
import CmsComponent from "@/components/editor/rendering/components/cms-component.vue";

// cms-component-social-links #hot-reload-debug
export default {
    name: `cms-component-social-links`,
    extends: CmsComponent,
    components: {CmsComponent},
    computed: {
        containerStyle() {
            let style = {
                fontWeight: this.component.bold ? `bold` : ``,
                fontStyle: this.component.italic ? `italic` : `normal`,
                "--navigation-spacing": this.component.mediaProxy.spacing
            }
            if (this.component.mediaProxy.borderRadius && this.component.mediaProxy.borderRadius !== `var(--border-radius)`) {
                style[`--border-radius`] = this.component.mediaProxy.borderRadius;
            }
            if (this.component.mediaProxy.linkSizeMode === `custom`) {
                style[`--link-size`] = this.component.mediaProxy.linkSize;
            }
            if (this.component.mediaProxy.separator) {
                style[`--separator-color`] = this.component.mediaProxy.separatorColor;
                style[`--separator-width`] = this.component.mediaProxy.separatorWidth;
            }
            return style;
        }
    },
    methods: {
        linkHref(link) {
            if (this.isEditMode) {
                return null;
            }
            return this.component.linkHref(link);
        }
    }
}
</script>

<style lang="scss" scoped>
.cms-component-social-links {
    display: flex;

    .btn {
        margin: var(--navigation-spacing);
        position: relative;
        display: inline-flex;
        align-items: center;
        min-height: 2.4em; // fix because line-height doesn't apply to flex without any text (icon only)

        &.display-text-icon-top {
            flex-direction: column;
            justify-content: center;

            .icon {
                margin-bottom: 0.25rem;
                line-height: inherit !important;
                vertical-align: auto !important;
            }
        }

        &.display-text-icon-left {
            .icon {
                margin-right: 0.25em;
            }
        }

        &.display-text-icon-right {
            .icon {
                order: 2;
                margin-left: 0.25em;
            }
        }

        &.display-icon {
            .icon {
                line-height: inherit !important;
                vertical-align: auto !important;
            }
        }
    }

    &.size-custom {
        .btn {
            width: var(--link-size);
            max-width: 100%;
        }
    }

    &.layout-vertical {
        flex-direction: column;

        .btn {
            display: flex;
            text-align: inherit;
        }

        &.show-separator {
            .btn {
                border-top: var(--separator-width) solid var(--separator-color);
            }
        }
    }

    &.layout-horizontal {
        flex-direction: row;

        &.size-justify {
            display: grid;
            grid-auto-flow: column;
            grid-auto-columns: minmax(0, 1fr);

            .btn {
                justify-content: center;
            }
        }

        &.show-separator {
            .btn + .btn:before {
                content: "";
                position: absolute;
                left: calc(var(--navigation-spacing) * -1);
                top: 0;
                width: 0;
                height: 100%;
                border-left: var(--separator-width) solid var(--separator-color);
            }
        }
    }

    &.fill-size {
        flex: 1;

        .btn {
            height: 100%;
            margin-top: 0;
            margin-bottom: 0;
        }
    }
}
</style>
