<template>
    <cms-component v-bind="$props" :style="style">
        <component class="cms-text" :is="componentTag" :class="componentClass">
            <template v-if="component.advanced">
                <i v-if="component.mediaProxy.leftIcon" class="fa-fw mr-1" :class="[fontAwesomePrefix, component.mediaProxy.leftIcon]"/>
                <span v-if="tr(component.mediaProxy.prefix)">{{tr(component.mediaProxy.prefix)}}</span>
            </template>

            <span v-if="useBinding && component.textField" v-html="dynamicText"/>
            <span v-else-if="component.widget" v-html="widgetText"/>
            <span v-else :contenteditable="selected" @input="edited" @blur="onBlur" v-html="text"/>

            <template v-if="component.advanced">
                <span v-if="tr(component.mediaProxy.suffix)">{{tr(component.mediaProxy.suffix)}}</span>
                <i v-if="component.mediaProxy.rightIcon" class="fa-fw ml-1" :class="[fontAwesomePrefix, component.mediaProxy.rightIcon]"/>
            </template>
        </component>
    </cms-component>
</template>

<script>
import CmsComponent from "@/components/editor/rendering/components/cms-component.vue";
import moment from "moment"

// cms-component-text #hot-reload-debug
export default {
    name: `cms-component-text`,
    extends: CmsComponent,
    components: {CmsComponent},
    data() {
        return {
            text: ``,
            widgetText: Math.random(),
            refreshInterval: null
        }
    },
    computed: {
        componentTag() {
            switch (this.component.mediaProxy.preset) {
                case `p1`:
                case `p2`:
                case `p3`:
                case `p4`:
                case `p5`:
                case `p6`:
                    return `p`;
                case `default`:
                    return `div`;
                default:
                    return this.component.mediaProxy.preset;
            }
        },
        componentClass() {
            switch (this.component.mediaProxy.preset) {
                case `p1`:
                case `p2`:
                case `p3`:
                case `p4`:
                case `p5`:
                case `p6`:
                    return this.component.mediaProxy.preset;
                default:
                    return ``;
            }
        },
        dynamicText() {
            if (!this.component.textField) {
                return;
            }
            let value = this.boundItem.getField(this.component.textField);
            if (!value)
                return value;

            if (this.component.format) {
                const values = this.component.format.split(`|`);
                if (values.length > 1) {
                    switch (values[0]) {
                        case `moment`:
                            return moment(value).format(values[1]);
                        case `tel`:
                            return value.replace(/\D/g, ``)
                                .replace(/(\d*)(\d{3})(\d{3})(\d{4})$/, (s, a, b, c, d) => `+${a} (${b}) ${c}-${d}`)
                                .replace(/\+(1\b|\s)\s*/, ``);
                    }
                }
            }
            return this.tr(value);
        },
        style() {
            let style = {
                fontWeight: this.component.bold ? `bold` : `400`,
                fontStyle: this.component.italic ? `italic` : `normal`,
                textDecoration: this.component.underline ? `underline` : `none`,
                textTransform: this.component.uppercase ? `uppercase` : `none`
            };
            if (this.component.bold) {
                style[`--body-font-weight`] = `bold`;
                style[`--headlines-font-weight`] = `bold`;
                style[`--titles-font-weight`] = `bold`;
            }
            if (this.component.color && this.component.color !== `default`) {
                const color = this.cmsWebsite.getColor(this.component.color);
                style[`--scheme-paragraph-color`] = color;
                style[`--scheme-title-color`] = color;
            }
            return style;
        }
    },
    mounted() {
        this.init();
    },
    created() {
        this.init();
    },
    beforeDestroy() {
        if (this.refreshInterval) {
            clearInterval(this.refreshInterval);
        }
    },
    methods: {
        init() {
            if (this.isEditMode) {
                this.text = this.component.getText(this.displayLanguage);
            } else {
                this.text = this.tr(this.component.text);
                if (this.component.widget === `time`) {
                    if (this.refreshInterval) {
                        clearInterval(this.refreshInterval);
                    }

                    this.refreshInterval = setInterval(this.updateWidget.bind(this), 30 * 1000);
                }
            }
            if (this.component.widget) {
                this.updateWidget();
            }
        },
        updateWidget() {
            switch (this.component.widget) {
                case `date`:
                    this.widgetText = moment().format(`ll`);
                    break;
                case `time`:
                    this.widgetText = moment().format(`LT`);
                    break;
            }
        },
        input() {

        },
        edited(value) {
            if (typeof this.component.newText !== `object`) {
                this.component.newText = {};
            }
            this.component.newText[this.displayLanguage] = value.target.innerHTML;
        },
        onBlur() {
            this.component.applyChanges();
            this.init();
        }
    },
    watch: {
        block() {
            this.init();
        },
        displayLanguage() {
            this.init();
        }
    }
}
</script>

<style lang="scss" scoped>
.cms-component-text {
    width: 100%;

    &.selected {
        .cms-text[contenteditable="true"] {
            cursor: text;

            &:hover {
                outline: 2px solid var(--accent-color);
            }
        }
    }
}

.cms-text {
    color: var(--scheme-paragraph-color);
    width: 100%;
}

div {
    &.cms-text {
        font-family: var(--body-font);
        font-weight: var(--body-font-weight);
        letter-spacing: var(--body-letter-spacing);
    }
}

h1,h2,h3,h4,h5,h6 {
    &.cms-text {
        color: var(--scheme-title-color);
    }
}

</style>
